import "../Styles/Login.css";
import food from "../Images/Login/Food.svg";
import logo from "../Images/Login/logo.svg";
import brown from "../Images/Login/background.svg";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { app } from "../Firebase/firebaseConfig";
import { BeatLoader } from "react-spinners";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { SMS, verifyOtp, CustomerInfo, fireOtp } from "../api";
import Footer from "../Components/Footer";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Link } from "react-router-dom";
import { useEffect } from "react";

function Login(props) {
  const { t, i18n } = useTranslation();
  const analytics = getAnalytics(app);
  const [loading, setLoading] = useState(false);
  const [errorMsgModel, setErrorMsgModel] = useState(false);
  const [msgAr, setMsgAr] = useState();
  const [msgEn, setMsgEn] = useState();
  const [isOtpScreen, setIsOtpScreen] = useState(false); // Toggle between phone input and OTP input
  const [otp, setOtp] = useState(""); // Store OTP
  const [phone, setPhone] = useState(""); // Store phone number
  const [resendTimer, setResendTimer] = useState(60); // 60 seconds timer
  const [canResend, setCanResend] = useState(false); // Disable resend initially

  const navigate = useNavigate();

  // Regular expression for phone validation
  let phoneRegEx =
    /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;

  // Form validation schema
  const Schema = yup.object().shape({
    phone: yup
      .string()
      .required("validation.phone_required")
      .matches(phoneRegEx, "validation.phone_not_valid"),
  });

  // Formik for phone number input
  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: Schema,
    onSubmit: handlePhoneSubmit,
  });

  const handleResendOtp = async () => {
    if (!canResend) return;

    setLoading(true);
    setCanResend(false); // Disable resend button until the timer resets

    try {
      // Call the OTP sending API
      const response = await fireOtp({ phone });

      if (response.data.message === "success") {
        setMsgAr("تم إرسال OTP بنجاح.");
        setMsgEn("OTP sent successfully.");
        setErrorMsgModel(false);
        startResendTimer(); // Start the timer
      } else {
        setErrorMsgModel(true);
        setMsgAr("فشل في إعادة إرسال OTP.");
        setMsgEn("Failed to resend OTP.");
      }
    } catch (error) {
      setErrorMsgModel(true);
      setMsgAr("خطأ أثناء إعادة إرسال OTP.");
      setMsgEn("Error while resending OTP.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOtpScreen) {
      startResendTimer();
    }
  }, [isOtpScreen]);

  const startResendTimer = () => {
    setResendTimer(60); // Reset the timer to 60 seconds
    const timer = setInterval(() => {
      setResendTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setCanResend(true); // Enable resend button after timer ends
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  // Handle phone number submission
  async function handlePhoneSubmit(values) {
    // setLoading(true);
    setPhone(values.phone);

    try {
      // Send OTP API call
      const response = await fireOtp({ phone: values.phone });

      if (response.data.message === "success") {
        console.log(response);
        setIsOtpScreen(true);
        setLoading(false);
        setErrorMsgModel(false);
      } else {
        setErrorMsgModel(true);
        setMsgAr("فشل في إرسال OTP");
        setMsgEn("Failed to send OTP.");
        setLoading(false);
      }
    } catch (error) {
      if (Number(error.response.status) == 429) {
       
        setErrorMsgModel(true);
        setMsgAr("قد قمت بمحاولات كثيرة رجاء المحاولة لاحقاُ ");
        setMsgEn("Too many attempts please try again later");
        setLoading(false);
        return
      }
      setErrorMsgModel(true);
      setMsgAr("خطأ في رقم الهاتف");
      setMsgEn("Invalid phone number");
      setLoading(false);
    }
  }

  // Handle OTP submission
  async function handleOtpSubmit(event) {
    event.preventDefault();
    setLoading(true);

    try {
      // Verify OTP API call
      const data = {
        phone: phone,
        OTP: otp,
        pushToken: " token",
      };
      const response = await verifyOtp(data);

      if (response.data.message === "success") {
        const token = response.data.data.accessToken;
        // Store common data in localStorage
        localStorage.setItem("token", token);
        localStorage.setItem("userPhone", phone);
        localStorage.setItem("accountType", response.data.data.profileType);
        localStorage.setItem(
          "organizationId",
          response.data.data.userOrganizationId
        );
        localStorage.setItem(
          "defaultOrganizationId",
          response.data.data.userOrganizationId
        );

        // Check if user is old
        if (response.data.data.isOld === true) {
          localStorage.setItem("verified", JSON.stringify(false));
          navigate("/code");

          // Reset form
          document.getElementsByName("loginForm")[0].reset();
        } else if (response.data.data.isVerified === true) {
          // User is verified
          localStorage.setItem("verified", JSON.stringify(true));

          // Fetch user info
          const customerResponse = await CustomerInfo(token);
          console.log(customerResponse);
          logEvent(analytics, "login", {
            phone,
            name: customerResponse.data.data.name,
          });
          localStorage.setItem("userName", customerResponse.data.data.name);

          // Reset form
          // document.getElementsByName("loginForm")[0].reset();

          // Redirect user
          if (localStorage.getItem("prevScreen") === "redirect") {
            navigate("/");
          } else {
            navigate(-1);
          }

          // Trigger cart change
          props.setCartChange(!props.cartChange);
        } else {
          // User is not verified, send OTP again
          setErrorMsgModel(true);
          setMsgAr("لم يتم تأكيد هذا الحساب من فضلك ادخل رمز التأكيد");
          setMsgEn("This account is not verified, please enter your code.");

          await SMS({
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          });

          navigate("/verify");
        }

        setLoading(false);
      } else {
        setErrorMsgModel(true);
        setMsgAr("رمز OTP غير صحيح.");
        setMsgEn("Invalid OTP.");
        setLoading(false);
      }
    } catch (error) {
      setErrorMsgModel(true);
      setMsgAr("خطأ في رمز OTP");
      setMsgEn("OTP error.");
      setLoading(false);
    }
  }

  return (
    <div>
      <div className="loginContainer container-fluid fluid">
        <div className="login">
          {loading && (
            <div className="loader" style={{ height: "60vh" }}>
              <BeatLoader color={"#E47100"} loading={loading} size={30} />
            </div>
          )}

          {!loading && (
            <div className="row">
              <div className="col">
                <div className="loginTitle">
                  <p>{t("Login.login")}</p>
                </div>

                {!isOtpScreen ? (
                  // Phone Number Input Screen
                  <>
                    <form
                      className="loginForm"
                      name="loginForm"
                      onSubmit={formik.handleSubmit}
                    >
                      <label>{t("contact.byPhone")}</label>
                      <input
                        type="text"
                        maxLength={10}
                        placeholder={t("contact.byPhone")}
                        value={formik.values.phone}
                        id="phone"
                        name="phone"
                        required
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          ); // Only allow numbers
                          formik.setFieldValue("phone", numericValue);
                        }}
                        onBlur={formik.handleBlur}
                        className={
                          (formik.errors.phone && formik.touched.phone) ||
                          errorMsgModel
                            ? "invalid"
                            : null
                        }
                      />
                      <span>
                        {formik.errors.phone && formik.touched.phone
                          ? t(formik.errors.phone)
                          : null}
                      </span>
                      <button style={{ marginTop: "20px" }} type="submit">
                        {t("Login.start")}
                      </button>
                    </form>
                    <div className="register">
                      <p> {t("Login.dontHave")}</p>
                      <Link to="/register">
                        <button> {t("Login.register")}</button>
                      </Link>
                    </div>
                  </>
                ) : (
                  // OTP Input Screen
                  <form className="loginForm" onSubmit={handleOtpSubmit}>
                    <label>{t("Login.otp")}</label>
                    <input
                      type="text"
                      maxLength={6}
                      placeholder={t("Login.enterOtp")}
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      required
                    />

                    <div style={{ marginTop: "1em", textAlign: "center" }}>
                      {canResend ? (
                        <span
                          style={{
                            display: "flex",

                            cursor: canResend ? "pointer" : "not-allowed",
                            color: canResend ? "#000" : "#aaa",
                          }}
                          onClick={handleResendOtp}
                          onMouseEnter={(e) =>
                            canResend
                              ? (e.target.style.textDecoration = "underline")
                              : null
                          }
                          onMouseLeave={(e) =>
                            (e.target.style.textDecoration = "none")
                          }
                        >
                          {t("Login.resendOtp")}
                        </span>
                      ) : (
                        <span
                          style={{
                            display: "flex",

                            cursor: canResend ? "pointer" : "not-allowed",
                            color: canResend ? "#000" : "#aaa",
                          }}
                        >{`${t("Login.resendOtp")} ${resendTimer} ${t(
                          "Login.seconds"
                        )}`}</span>
                      )}
                    </div>

                    <button style={{ marginTop: "20px" }} type="submit">
                      {t("Login.verifyOtp")}
                    </button>
                  </form>
                )}

                {errorMsgModel && (
                  <span style={{ margin: "2em 0" }}>
                    {i18n.language === "ar" ? msgAr : msgEn}
                  </span>
                )}
              </div>

              <div
                className={
                  i18n.language === "ar"
                    ? "col loginImagesAr"
                    : "col loginImages"
                }
              >
                <img className="img1" src={brown} alt="background" />
                <img className="img2" src={food} alt="food" />
                <img className="img3" src={logo} alt="logo" />
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Login;
