import "../Styles/UserProfile.css";
import { useRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useFormik } from "formik";
import { BiEdit } from "react-icons/bi";
import Footer from "../Components/Footer";
import { CustomerInfo, editPicture, updateCustomer } from "../api";
import ProfileChangePass from "./ProfileChangePass";
import UserNav from "../Components/UserNav";
import Logout from "../Components/Logout";
import { BeatLoader } from "react-spinners";
import pp from "../Images/defaultImg.jpeg";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import { BsCalendar4Week } from "react-icons/bs";
import { Get_Subscriptions } from "../api";
import { SubscripedPackage } from "../Components/SubscribedPackage";

function PersonalData(props) {
  let history = useNavigate();
  const imgRef = useRef();
  const [preview, setPreview] = useState();
  const [image, setImage] = useState();
  const [chagePass, setChangePass] = useState(false);
  const [edit, setEdit] = useState(false);
  const [photo, setPhoto] = useState();
  const { t, i18n } = useTranslation();
  const [msgEn, setMsgEn] = useState();
  const [msgAr, setMsgAr] = useState();
  const [errorMsgModel, setErrorMsgModel] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [date, setDate] = useState("");
  const [initialDate, setInitialDate] = useState("");
  const [invoiceType, setInvoiceType] = useState(
    localStorage.getItem("accountType")
      ? localStorage.getItem("accountType")
      : "Personal User"
  );
  const [isUserSupscriped, setIsUserSubscribed] = useState(false);
  const [userSubscription, setUserSubscription] = useState(null);

  const [dateErr, setDateErr] = useState();
  let currencySymbol = "";

  let phoneRegEx =
    /^(05)[0-9]{8}$|^(03)[0-9]{8}$|^(3)[0-9]{7}$|^(6)[0-9]{7}$|^(7)[0-9]{7}$/;
  let emailRegx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const Schema = yup.object().shape({
    phone: yup
      .string()
      .required(t("validation.phone_required"))
      .matches(phoneRegEx, { message: t("validation.phone_not_valid") }),
    name: yup.string().required(t("validation.name_required")),
    message: yup.string().required(t("validation.message_required")),
    email: yup
      .string()
      .required(t("validation.email_required"))
      .matches(emailRegx, { message: t("validation.invalid_email") }),
  });
  let language = i18n.language === "ar" ? "ar" : "en";

  const formik = useFormik({
    initialValues: {
      name: name,
      phone: phone,
    },
    validationSchema: Schema,
  });

  const phoneRef = useRef(phone);

  const nameRef = useRef(name);

  useEffect(() => {
    setLoading(true);

    let countryId = props.country == 0 ? 1 : 2;
    const token = localStorage.getItem("token");
    Get_Subscriptions(language, countryId, token)
      .then((res) => {
        setIsUserSubscribed(
          res.data.data.userSubscription == null ? false : true
        );
        if (res.data.data.userSubscription != null) {
          setUserSubscription(res.data.data.userSubscription);
        }
        console.log(userSubscription);
        setLoading(false);
      })

      .catch((err) => {
        setLoading(false);
      });
  }, [i18n.language, props.country]);

  if (userSubscription) {
    if (userSubscription.package.countryId === 1) {
      currencySymbol = language === "ar" ? "ريال" : "SAR";
    } else if (userSubscription.package.countryId === 2) {
      currencySymbol = language === "ar" ? "دينار بحريني" : "BHD";
    } else {
    }
  }

  //fetch  user data
  useEffect(() => {
    //for image formate to be displayable
    if (preview) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(preview);
    }

    const token = localStorage.getItem("token");

    CustomerInfo(token)
      .then((response) => {
        setImage(response.data.data.photo);
        setPhoto(response.data.data.photo);
        setName(response.data.data.name);
        setPhone(response.data.data.phone);
        if (response.data.data.birthDate == null) {
          setStartDate("");
          setDate("");
          setInitialDate("");
        } else {
          let minDate = new Date(new Date().getFullYear() - 80, 0, 1);
          let maxDate = new Date(new Date().getFullYear() - 16, 11, 31);
          let startDate = new Date(response.data.data.birthDate);
          if (startDate >= minDate && startDate <= maxDate) {
            console.log("between");

            setStartDate(startDate);
            setDate(response.data.data.birthDate);
            setInitialDate(response.data.data.birthDate);
          } else {
            console.log("empty");
            setStartDate("");
            setDate(response.data.data.birthDate);
            setInitialDate(response.data.data.birthDate);
          }
        }

        formik.values.phone = response.data.data.phone;
        formik.values.name = response.data.data.name;
        setLoading(false);
      })
      .catch((err) => {});
  }, [edit]);

  //resets changes made in user data
  function cancleEdit() {
    const token = localStorage.getItem("token");

    CustomerInfo(token)
      .then((response) => {
        setImage(photo);
        document.getElementById("name").value = name;
        document.getElementById("phone").value = phone;
      })
      .catch((err) => {
        if (err.response.status === 401) {
          //used to change cart icon's current value to 0

          props.setCartItem(0);

          localStorage.removeItem("token");
          localStorage.removeItem("userPhone");
          localStorage.removeItem("userName");
          localStorage.removeItem("basket");
          //was used to store user's current step in cart process
          localStorage.removeItem("process");

          history("/login");
        }
      });
  }

  //for image formate to be displayable rerenders onChanging image input
  useEffect(() => {
    if (preview) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(preview);
    }
    setLoading(false);
  }, [preview]);

  //each input is handled separately
  // if(preview) => *if image got changed* ()=> editPicture api is called and image is updated

  //if phone number got changed updateCustomer api is called then user is sent an otp and redirected to "/verify-phone" (UpdatePhone component found in /Pages)
  //if name number got changed updateCustomer api is called and user name is updated

  function handleSubmit(e) {
    e.preventDefault();

    if (date == "" || startDate == "") {
      setDateErr(true);
    } else {
      const token = localStorage.getItem("token");
      if (preview) {
        setLoading(true);

        const formData = new FormData();
        formData.append("photo", preview);
        formData.append(" _method", "PUT");

        editPicture(formData, token)
          .then((response) => {
            setEdit(!edit); //to rerender usernav to update changed data
            setLoading(false);
          })
          .catch(function (err) {
            if (err.response.status === 401) {
              //used to change cart icon's current value to 0

              props.setCartItem(0);

              localStorage.removeItem("token");
              localStorage.removeItem("userPhone");
              localStorage.removeItem("userName");
              localStorage.removeItem("basket");
              //was used to store user's current step in cart process
              localStorage.removeItem("process");

              history("/login");
            }
            setLoading(false);
          });
      }

      const values = {
        phone: phoneRef.current.value,
      };
      if (phone !== phoneRef.current.value) {
        setLoading(true);

        updateCustomer(values, token)
          .then((response) => {
            history("/verify-phone");
            localStorage.setItem("newPhone", values.phone);
            /* CustomerInfo(token).then((response) => {
     localStorage.setItem("userName",response.data.data.name);
       localStorage.setItem("userPhone",values.phone);
    // window.location.reload();
    setLoading(false)  
     }).catch(function (error) {
           if (error.response.status === 422) {
                        
             setMsgAr("هذا الرقم مسجل من قبل");
                setMsgEn("This phone already exists");
                  setErrorMsgModel(true);
                  setLoading(false)  
                      }   else {
                           console.log(error)
                           setLoading(false)  
                      }
                  
                  })*/
          })
          .catch(function (error) {
            if (error.response.status === 401) {
              //used to change cart icon's current value to 0

              props.setCartItem(0);

              localStorage.removeItem("token");
              localStorage.removeItem("userPhone");
              localStorage.removeItem("userName");
              localStorage.removeItem("basket");
              //was used to store user's current step in cart process
              localStorage.removeItem("process");

              history("/login");
            } else if (error.response.status === 422) {
              setMsgAr("هذا الرقم مسجل من قبل");
              setMsgEn("This phone already exists");
              setErrorMsgModel(true);
              setLoading(false);
            } else {
              console.log(error);
              setLoading(false);
            }
          });
      } else if (name !== nameRef.current.value || initialDate !== date) {
        setLoading(true);

        let valuesUpdated = {
          name: nameRef.current.value,
          birthDate: date,
        };
        updateCustomer(valuesUpdated, token)
          .then((response) => {
            CustomerInfo(token)
              .then((response) => {
                localStorage.setItem("userName", response.data.data.name);
                setDateErr(false);
                // window.location.reload();
                setEdit(!edit); //to rerender usernav to update changed data
                setLoading(false);
              })
              .catch(function (error) {
                if (error.response.status === 401) {
                  //used to change cart icon's current value to 0

                  props.setCartItem(0);

                  localStorage.removeItem("token");
                  localStorage.removeItem("userPhone");
                  localStorage.removeItem("userName");
                  localStorage.removeItem("basket");
                  //was used to store user's current step in cart process
                  localStorage.removeItem("process");

                  history("/login");
                } else if (error.response.status === 422) {
                  setMsgAr("هذا الرقم مسجل من قبل");
                  setMsgEn("This phone already exists");
                  setErrorMsgModel(true);
                  setLoading(false);
                } else {
                  console.log(error);
                  setLoading(false);
                }
              });
          })
          .catch((err) => {
            if (err.response.status === 401) {
              //used to change cart icon's current value to 0

              props.setCartItem(0);

              localStorage.removeItem("token");
              localStorage.removeItem("userPhone");
              localStorage.removeItem("userName");
              localStorage.removeItem("basket");
              //was used to store user's current step in cart process
              localStorage.removeItem("process");

              history("/login");
            }
          });
      }
    }
  }

  function handleChangeAccount(e) {
    const token = localStorage.getItem("token");
    const values = {
      profileType: e.target.value,
    };
    updateCustomer(values, token)
      .then((response) => {
        setInvoiceType(e.target.value);
        localStorage.setItem("accountType", e.target.value);
        if (e.target.value == "Company User") {
          history("/profile/tax-invoice");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          //used to change cart icon's current value to 0

          props.setCartItem(0);

          localStorage.removeItem("token");
          localStorage.removeItem("userPhone");
          localStorage.removeItem("userName");
          localStorage.removeItem("basket");
          //was used to store user's current step in cart process
          localStorage.removeItem("process");

          history("/login");
        }
      });
  }
  const handleChangeDate = (date) => {
    if (date.toLocaleDateString() === new Date().toLocaleDateString()) {
      setStartDate(date);
    } else {
      setStartDate(date);
    }

    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();

    if (d < 10) {
      d = "0" + d;
    }
    if (m < 10) {
      m = "0" + m;
    }
    let selectedDate = y + "-" + m + "-" + d;

    setDate(selectedDate);
  };

  return (
    <div>
      {modal ? (
        <Logout setModal={setModal} setCartItem={props.setCartItem} />
      ) : null}
      <div className="userProfile">
        <UserNav setModal={setModal} nav={5} edit={edit} />
        {loading ? (
          <div className="loader profileLoader">
            <BeatLoader color={"#E47100"} loading={loading} size={30} />
          </div>
        ) : chagePass ? (
          <ProfileChangePass
            setChangePass={setChangePass}
            setCartItem={props.setCartItem}
          />
        ) : (
          <div className="aboutusBody profileBody container-fluid fluid">
            <p className="upTable-title"> {t("profile.personal")}</p>

            <div className="userData">
              <form onSubmit={(e) => handleSubmit(e)} name="personalData">
                <div className="upTop">
                  <div
                    style={{ position: "relative" }}
                    className="upImageBorder"
                  >
                    <button
                      type="button"
                      className="editImage"
                      onClick={() => imgRef.current.click()}
                    >
                      {" "}
                      <BiEdit size={30} />
                    </button>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      ref={imgRef}
                      onChange={(e) => {
                        console.log("change");
                        const file = e.target.files[0];
                        if (file) {
                          setPreview(file);
                        }
                      }}
                    />
                    <div
                      className="profileImage "
                      style={{ backgroundImage: `url(${image ? image : pp})` }}
                    ></div>
                  </div>
                </div>

                <div>
                  <div className="inputDiv">
                    <label> {t("Login.yourName")}</label>

                    <input
                      type="text"
                      value={formik.values.name}
                      id="name"
                      name="name"
                      ref={nameRef}
                      required={true}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.name && formik.touched.name
                          ? "invalid"
                          : null
                      }
                    />
                    <span>
                      {formik.errors.name && formik.touched.name
                        ? formik.errors.name
                        : null}
                    </span>
                  </div>
                </div>

                <div>
                  <div className="inputDiv">
                    <label> {t("Login.yourPhone")}</label>
                    <input
                      type="text"
                      value={formik.values.phone}
                      id="phone"
                      name="phone"
                      ref={phoneRef}
                      required={true}
                      onFocus={() => setErrorMsgModel(false)}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        (formik.errors.phone && formik.touched.phone) ||
                        errorMsgModel
                          ? "invalid"
                          : null
                      }
                    />
                    <span>
                      {formik.errors.phone && formik.touched.phone
                        ? formik.errors.phone
                        : null}
                    </span>
                    {i18n.language == "ar" ? (
                      <span>{errorMsgModel ? msgAr : null}</span>
                    ) : (
                      <span>{errorMsgModel ? msgEn : null}</span>
                    )}
                  </div>
                </div>

                <div>
                  <div className="inputDiv">
                    <label> {t("Login.birthday")}</label>
                    <div
                      className={dateErr ? "invalid-date" : null}
                      style={{
                        position: "relative",
                        width: "100%",
                        display: "inline-block",
                      }}
                    >
                      <DatePicker
                        selected={startDate}
                        placeholderText={initialDate}
                        onChange={handleChangeDate}
                        minDate={new Date(new Date().getFullYear() - 80, 0, 1)} // 80 years ago
                    maxDate={new Date(new Date().getFullYear() - 17, 11, 31)} // 16 years ago
                        showYearDropdown
                        yearDropdownItemNumber={15}
                        scrollableYearDropdown
                      />
                      <BsCalendar4Week
                        size={25}
                        style={
                          i18n.language == "ar"
                            ? { left: "10px", right: "auto" }
                            : { right: "10px", left: "auto" }
                        }
                        className="calendarIcon"
                      />
                    </div>
                    {dateErr ? (
                      <span>{t("validation.birthday_required")}</span>
                    ) : null}
                  </div>
                </div>

                <div>
                  <div className="inputDiv customerType">
                    <p className="cpForm"> {t("profile.customerType")}:</p>

                    <div className="row">
                      <div className="col-6">
                        <input
                          checked={invoiceType == "Personal User"}
                          value="Personal User"
                          name="invoice"
                          type="radio"
                          onChange={(e) => handleChangeAccount(e)}
                        />
                        <label>{t("profile.regular")}</label>
                      </div>
                      <div className="col-6">
                        <input
                          checked={invoiceType == "Company User"}
                          value="Company User"
                          name="invoice"
                          type="radio"
                          onChange={(e) => handleChangeAccount(e)}
                        />
                        <label>{t("profile.tax")}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div></div>

                <div></div>
                <div></div>
                <div>
                  {/* <div className="inputDiv">
                    <button
                      type="button"
                      onClick={() => setChangePass(true)}
                      className="changePass"
                    >
                      <p> {t("profile.change_passQ")}</p>
                    </button>
                  </div> */}
                </div>
                <div >
                  {userSubscription && (
                    <SubscripedPackage userSubscription={userSubscription} />
                  )}
                </div>

                <div className="formBtns">
                  <button type="submit" className="dataEdit">
                    {t("profile.save")}
                  </button>
                  <span> </span>
                  <button
                    type="button"
                    className="cancelEdit"
                    onClick={cancleEdit}
                  >
                    {" "}
                    {t("profile.cancel")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default PersonalData;
